.ReactModal__Overlay {
    align-items: flex-end;
  }
  
  .ReactModal__Content {
    background: #ffffff;
    border: none !important;
    width: 1096px !important;
    height: 752px !important;
  
    opacity: 0;
    transform: translateY(300px);
    transition: all 300ms ease-in-out;
  }
  
  .ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0px);
  }
  
  .ReactModal__Content--before-close {
    opacity: 0;
    transform: translateY(300px);
  }
  
  .MiddleModal__Content {
    min-width: 366px;
    max-width: 750px;
    height: 282px;
    background: #ffffff !important;
    border-radius: 20px;
    padding: 16px;
    outline: none;
    position: relative;
  
    -webkit-tap-highlight-color: transparent;
  }
  
  .MiddleModal__Overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000060;
    z-index: 9999 !important;
  
    transition: opacity 300ms;
  }
  
  .MiddleModal__Content--before-close {
    opacity: 0;
    transition: opacity 300ms;
  }
  

.ScenarioModal__Content {
  border-radius: .75rem;
}